<template>
    <div class="permission-group-form">
        <h1>{{ title }}</h1>

        <div class="permission-group-form__card">
            <div class="permission-group-form__card--title mb-6">
                {{ $t('Permissions') }}
            </div>
            <div class="group-form-divider mb-9"></div>

            <div class="permission-group-form__item mb-6">
                <div class="permission-group-form__item--label">
                    {{ $t('Permission Group Name') }}
                </div>
                <div class="permission-group-form__item--input">
                    <el-input v-model="formData.group_name" />
                    <div v-if="submitted && !formData.group_name" class="input-error">
                        {{ $t('Group Name is required') }}
                    </div>
                </div>
            </div>

            <div class="group-form-divider mb-9"></div>

            <div class="permission-group-form__item mb-15">
                <div class="permission-group-form__item--label">
                    {{ $t('Functions') }}
                    <el-tooltip
                        effect="dark"
                        placement="bottom-start"
                    >
                        <img src="/media/buying/icons/info_black.svg" alt="">
                        <template #content>
                            <div class="permission-tooltip">
                                {{ $t('If all functions are disabled, user will be in a view-only mode.') }}
                            </div>
                        </template>
                    </el-tooltip>
                </div>
                <div class="permission-group-form__item--input">
                    <div class="group-form-switch mb-2">
                        <div class="group-form-switch-label switch-bold">
                            {{ $t('All') }}
                        </div>
                        <el-switch v-model="allFunctions" @change="handleChangedAll" />
                    </div>
                    <div class="group-form-divider"></div>

                    <div
                        class="group-form-switch mb-2 mt-4"
                        v-for="(item, key) in usersPermissions"
                    >
                        <div class="group-form-switch-label">
                            {{ $t(item) }}
                        </div>

                        <label class="switch">
                            <input
                                v-model="formData.permissions"
                                type="checkbox"
                                :name="key"
                                :value="key"
                            >
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="permission-group-form__item mb-6">
                <div class="permission-group-form__item--label">
                    {{ $t('Access') }}
                    <el-tooltip
                        effect="dark"
                        placement="bottom-start"
                    >
                        <img src="/media/buying/icons/info_black.svg" alt="">
                        <template #content>
                            <div class="permission-tooltip">
                                {{ $t('Give access to specific product areas, countries or customers by selecting them in the boxes on the right. Selecting all will give access to future additions also.') }}
                            </div>
                        </template>
                    </el-tooltip>
                </div>
                <div class="permission-group-form__item--input">
                    <div class="group-form-input mb-4">
                        <div class="group-form-input-label">
                            <h4>{{ $t("Product Areas") }}</h4>
                            <button
                                v-if="productAreasList.length && formData.product_areas.length !== productAreasList.length"
                                @click="resetToDefault('product_areas', productAreasList)"
                            >{{ $t('Reset to default') }}</button>
                        </div>
                        <SelectBoxWithModal
                          v-model="formData.product_areas"
                          self-value
                          multiple
                          :options="productAreasList"
                          :is-mandatory-select="false"
                          :all-selected-text="$t('All Selected')"
                          :custom-selection-text="`${formData.product_areas.length} ${$t('Permitted')}`"
                          :placeholder="$t('Select Product Areas')"
                          :modal-title="$t('Product Areas')"
                          :modal-subtitle="$t('Select TYPE you want to allow access to', { type: $t('Product Areas') })"
                        />
                    </div>
                    <div class="group-form-input mb-4">
                        <div class="group-form-input-label">
                            <h4>{{ $t("Countries") }}</h4>
                            <button
                                v-if="countriesList.length && formData.countries.length !== countriesList.length"
                                @click="resetToDefault('countries', countriesList)"
                            >{{ $t('Reset to default') }}</button>
                        </div>
                        <SelectBoxWithModal
                          v-model="formData.countries"
                          self-value
                          multiple
                          :options="countriesList"
                          :is-mandatory-select="false"
                          :all-selected-text="$t('All Selected')"
                          :custom-selection-text="`${formData.countries.length} ${$t('Permitted')}`"
                          :placeholder="$t('Select Countries')"
                          :modal-title="$t('Countries')"
                          :modal-subtitle="$t('Select TYPE you want to allow access to', { type: $t('Countries') })"
                        />
                    </div>
                    <div class="group-form-input">
                        <div class="group-form-input-label">
                            <h4>{{ $t("Customers") }}</h4>
                            <button
                                v-if="customersList.length && formData.customers.length !== customersList.length"
                                @click="resetToDefault('customers', customersListIds)"
                            >{{ $t('Reset to default') }}</button>
                        </div>
                        <CustomersBanksSelectBox
                          v-model="formData.customers"
                          multiple
                          :options="customersList"
                          :is-mandatory-select="false"
                          :placeholder="$t('Select Customers')"
                          :modal-title="$t('Customers')"
                          :modal-subtitle="$t('Select TYPE you want to allow access to', { type: $t('Customers') })"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal.vue";
import CustomersBanksSelectBox from "@/buying-teams/shared-components/settings/CustomersBanksSelectBox.vue";
import SelectionModalTable from "@/buying-teams/shared-components/settings/SelectionModalTable.vue";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

const usersPermissions = {
    [BankUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION]: 'Create feedback session',
    [BankUserPermissionTypeEnum.INVITE_CUSTOMERS]: 'Invite customers',
    [BankUserPermissionTypeEnum.COMMENT_REQUEST]: 'Comment on requests',
    [BankUserPermissionTypeEnum.COMMENT_IDEA]: 'Comment on ideas',
    [BankUserPermissionTypeEnum.OPEN_CLOSE_REQUEST_IDEA]: 'Open/close requests and ideas',
};

export default {
    name: "PermissionGroupForm",
    components: {
        SelectionModalTable,
        CustomersBanksSelectBox,
        SelectBoxWithModal
    },
    props: {
        title: String,
        submitted: Boolean,
        permissionData: {
            type: Object,
            default: null,
        },
        customersList: Array,
        usersList: Array,
        productAreasList: Array,
        countriesList: Array,
    },
    emits: ['onFormChange'],
    data() {
        return {
            allFunctions: false,
            usersPermissions,
            formData: {
                group_name: '',
                permissions: [],
                countries: [],
                product_areas: [],
                customers: [],
            }
        }
    },
    computed: {
        isFormValid() {
            return !!this.formData.group_name;
        },
        customersListIds() {
            return this.customersList ? this.customersList.map(c => c.id) : []
        },
    },
    watch: {
        'formData': {
            deep: true,
            handler(newVal) {
                if (newVal) {
                    this.$emit('onFormChange', newVal);
                }
            }
        },
        'formData.permissions': {
            deep: true,
            handler(newVal) {
                this.allFunctions = newVal.length === Object.keys(usersPermissions).length;
            }
        }
    },
    methods: {
        handleChangedAll(value) {
            this.formData.permissions = value ? Object.keys(usersPermissions) : [];
        },
        setPermissionData() {
            if (this.permissionData && Object.keys(this.permissionData).length) {
                for (let [key] of Object.entries(this.formData)) {
                    this.formData[key] = this.permissionData[key];
                }
            } else {
                this.formData.countries = this.countriesList;
                this.formData.product_areas = this.productAreasList;
                this.formData.customers = this.customersListIds;
                this.formData.permissions = Object.keys(usersPermissions);
            }
        },
        resetToDefault(key, allList) {
            this.formData[key] = [...allList];
        }
    },
    mounted() {
        this.setPermissionData();
    }
}
</script>

<style lang="scss">
.permission-group-form {
    h1 {
        color: #434343;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        margin-top: 32px;
        margin-left: 4px;
        margin-bottom: 20px;
    }

    .group-form-divider {
        width: 100%;
        height: 1px;
        background: #EBEEF5;
    }

    .input-error {
        margin-top: 7px;
        color: red;
    }

    &__card {
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 9px 29px 0px rgba(0, 0, 0, 0.06);
        padding: 32px;

        &--title {
            color: #242424;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
        }
    }

    &__item {
        display: flex;
        &--label {
            max-width: 300px;
            width: 30%;
            color: #8B8B8B;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
        }
        &--input {
            max-width: 550px;
            width: 70%;
            .group-form-input-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                h4 {
                    color: #595959;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    margin-bottom: 0;
                }
                button {
                    color: #435BF4;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15px;
                    background: transparent;
                    border: none;
                    padding: 0;
                    &:hover {
                        opacity: .8;
                    }
                }
            }
        }
    }

    .el-input__inner {
        height: 56px;
        border-radius: 4px;
        background: #F6F6F6;
        border: none;
        padding: 15px;
        color: #606266;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
    }

    .group-form-switch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .group-form-switch-label {
            color: #242424;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            &.switch-bold {
                font-weight: 700;
            }
        }
    }
}
.permission-tooltip {
    max-width: 292px;
}
</style>
